import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import React from 'react';
// @mui
import { Container, Tab, Box, Typography, Tabs, Grid, useTheme, Button, Modal, TextField } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
// sections
import {
    AccountGeneral,
    AccountTransactions,
    AccountIntegrations,
    AccountNotifications,
    AccountUsers,
    AccountHistory
} from '../../sections/@dashboard/client/account';
import { AppAreaInstalled, AppNewInvoice, AppWidgetSummary } from 'src/sections/@dashboard/general/app';
import { useParams } from 'react-router';
import { get_client_details, get_client_overview, client_withdrawal, client_deposit, upload_deposit_receipt } from 'src/api/API';
import AccountPayments from 'src/sections/@dashboard/client/account/AccountPayments';
import toast from 'react-simple-toasts';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

export default function ClientDetails() {
    const { themeStretch } = useSettings();
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState('general');
    const [websiteId, setWebsiteId] = useState(0);
    const [overviewData, setOverviewData] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [imageUploaded, setImageUploaded] = useState('');


    const [withdrawalModal, setWithdrawalModal] = React.useState(false);
    const [withdrawalAmount, setWithdrawalAmount] = React.useState(0);
    const [withdrawalError, setWithdrawalError] = React.useState(false);
    const [withdrawalErrorText, setWithdrawalErrorText] = React.useState('');
    const { allLang, currentLang, translate, onChangeLang } = useLocales();

    const [depositModal, setDepositModal] = React.useState(false);
    const [depositForm, setDepositForm] = React.useState({
        amount: 0,
        remarks: "",
        image: ""
    });

    const params = useParams();
    useEffect(() => {
        get_overview();
        get_details();
        setWebsiteId(params.client_id);
    }, []);
    const get_overview = async () => {
        var response = await get_client_overview({
            website_id: params.client_id
        });
        if (response) {
            setOverviewData(response.data);
        }
    };

    const get_details = async () => {
        var response = await get_client_details({
            website_id: params.client_id
        });

        if (response.data.length) {
            setClientData(response.data[0]);
        }

    }

    const refresh = () => {
        get_details();
        get_overview();
    };

    const handleChangeImageAttachment = async (e) => {
        if (e.target.files) {
            var formData = new FormData();

            formData.append("file", e.target.files[0]);
            var response = await upload_deposit_receipt(formData);
            if (response.status) {
                setImageUploaded(response.data);
            } else {
                toast("Image not allowed");
            }
        }
    };
    const ACCOUNT_TABS = [
        {
            value: 'general',
            icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
            component: <AccountGeneral data={clientData} />,
        },
        {
            value: 'transactions',
            icon: <Iconify icon={'ic:baseline-payment'} width={20} height={20} />,
            component: <AccountTransactions cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} />,
        },
        {
            value: 'integration',
            icon: <Iconify icon={'ic:outline-integration-instructions'} width={20} height={20} />,
            component: <AccountIntegrations />,
        },
        {
            value: 'accounts',
            icon: <Iconify icon={'ic:round-supervised-user-circle'} width={20} height={20} />,
            component: <AccountUsers myProfile={_userAbout} />,
        },
        {
            value: 'Channels',
            icon: <Iconify icon={'ic:baseline-menu'} width={20} height={20} />,
            component: <AccountPayments myProfile={_userAbout} />,
        },
        {
            value: 'history',
            icon: <Iconify icon={'ic:baseline-history'} width={20} height={20} />,
            component: <AccountHistory myProfile={_userAbout} />,
        },

    ];

    const ACCOUNT_TABS_USER = [
        {
            value: 'general',
            icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
            component: <AccountGeneral data={clientData} />,
        },
        {
            value: 'transactions',
            icon: <Iconify icon={'ic:baseline-payment'} width={20} height={20} />,
            component: <AccountTransactions cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} />,
        },
        {
            value: 'integration',
            icon: <Iconify icon={'ic:outline-integration-instructions'} width={20} height={20} />,
            component: <AccountIntegrations />,
        },
        // {
        //     value: 'accounts',
        //     icon: <Iconify icon={'ic:round-supervised-user-circle'} width={20} height={20} />,
        //     component: <AccountUsers myProfile={_userAbout} />,
        // },
        // {
        //     value: 'Channels',
        //     icon: <Iconify icon={'ic:baseline-menu'} width={20} height={20} />,
        //     component: <AccountPayments myProfile={_userAbout} />,
        // },
        {
            value: 'history',
            icon: <Iconify icon={'ic:baseline-history'} width={20} height={20} />,
            component: <AccountHistory myProfile={_userAbout} />,
        },

    ];

    const handleChangeWithdrawal = (e) => {
        setWithdrawalAmount(e.target.value);

        if (parseFloat(e.target.value) > parseFloat(overviewData.balance)) {
            setWithdrawalError(true);
            setWithdrawalErrorText("You can only withdraw up to " + overviewData.balance);
        } else {
            setWithdrawalError(false);
            setWithdrawalErrorText("");
        }
    };

    const handleChangeDeposit = (e) => {
        setDepositForm({
            ...depositForm,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitDeposit = async () => {
        if (depositForm.amount > 0) {

            var response = await client_deposit({
                ...depositForm,
                receipt: imageUploaded,
                website_id: websiteId
            });
            if (response.status) {
                toast("Submitted");
                setDepositModal(false);
            }
        } else {
            alert("Invalid amount");
        }
    }

    const handleSubmitWithdrawal = async () => {
        if (withdrawalAmount > overviewData.balance) {
            return;
        }
        var response = await client_withdrawal({
            website_id: websiteId,
            amount: withdrawalAmount
        });
        if (response.status) {
            toast("Request submitted");
            setWithdrawalModal(false);
            get_overview();
        }

    };
    return (
        <Page title="User: Account Settings">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Account"
                    links={[

                        { name: translate('Client'), href: PATH_DASHBOARD.clients.root },
                        { name: translate('Account Settings') },
                    ]}
                />

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Button variant="contained"
                            onClick={() => {
                                setDepositModal(true);
                            }}
                        >
                            <ArrowCircleDownIcon style={{
                                marginRight: 6
                            }} />
                            {translate("Deposit")}</Button>
                        {process.env.REACT_APP_USER_TYPE == 'admin' && (
                            <Button variant="contained" style={{
                                marginLeft: 10
                            }}
                                onClick={() => {
                                    setWithdrawalModal(true);
                                }}
                            >
                                <ArrowCircleUpIcon style={{
                                    marginRight: 6
                                }} />
                                {translate("Withdraw")}</Button>
                        )}

                    </Grid>
                    {/* <Grid item xs={12} md={8}>
            <AppWelcome displayName={user?.displayName} />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppFeatured />
          </Grid> */}

                    {currentTab == 'general' && overviewData != null && (
                        <>
                            <Grid item xs={12} md={3}>
                                <AppWidgetSummary
                                    title={translate("Total Received")}
                                    percent={2.6}
                                    total={parseFloat(overviewData.payment).toFixed(2)}
                                    chartColor={theme.palette.primary.main}
                                    chartData={[5, 18, 12, 51, 68, 11, 39, 37, 27, 20]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AppWidgetSummary
                                    title={translate("Balance")}
                                    percent={0.2}
                                    total={parseFloat(overviewData.balance).toFixed(2)}
                                    chartColor={theme.palette.chart.blue[0]}
                                    chartData={[20, 41, 63, 33, 28, 35, 50, 46, 11, 26]}
                                />

                            </Grid>

                            <Grid item xs={12} md={3}>
                                <AppWidgetSummary
                                    title={translate("Withdrawals")}
                                    percent={-0.1}
                                    total={parseFloat(overviewData.withdrawals).toFixed(2)}
                                    chartColor={theme.palette.chart.red[0]}
                                    chartData={[8, 9, 31, 8, 16, 37, 8, 33, 46, 31]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AppWidgetSummary
                                    title={translate("Platform Charges")}
                                    percent={-0.1}
                                    total={parseFloat(overviewData.fees).toFixed(2)}
                                    chartColor={theme.palette.chart.red[0]}
                                    chartData={[8, 9, 31, 8, 16, 37, 8, 33, 46, 31]}
                                />
                            </Grid>
                        </>
                    )}




                </Grid>

                <Tabs
                    style={{
                        marginTop: 20
                    }}
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >

                    {process.env.REACT_APP_USER_TYPE == 'admin' && (

                        ACCOUNT_TABS.map((tab) => (
                            <Tab disableRipple key={tab.value}

                                label={translate(capitalCase(tab.value))} icon={tab.icon} value={tab.value} />
                        ))
                    )}

                    {process.env.REACT_APP_USER_TYPE == 'admin' && (

                        ACCOUNT_TABS_USER.map((tab) => (
                            <Tab disableRipple key={tab.value} label={translate(capitalCase(tab.value))} icon={tab.icon} value={tab.value} />
                        ))
                    )}




                </Tabs>

                <Box sx={{ mb: 5 }} />



                {
                    currentTab == "general" && clientData && (
                        <AccountGeneral refresh={refresh} websiteId={websiteId} data={clientData} />
                    )
                }

                {
                    currentTab == "transactions" && clientData && (
                        <AccountTransactions
                            websiteId={websiteId}
                            cards={_userPayment} refresh={refresh} addressBook={_userAddressBook} invoices={_userInvoices} />
                    )
                }
                {
                    currentTab == "integration" && clientData && (
                        <AccountIntegrations refresh={refresh} data={clientData} websiteId={websiteId} />
                    )
                }
                {
                    currentTab == "accounts" && clientData && (
                        <AccountUsers refresh={refresh}
                            websiteId={websiteId} />
                    )
                }

                {
                    currentTab == "Channels" && clientData && (
                        <AccountPayments
                            data={clientData} refresh={refresh} websiteId={websiteId} />
                    )
                }
                {
                    currentTab == "history" && clientData && (
                        <AccountHistory
                            data={clientData} refresh={refresh} websiteId={websiteId} />
                    )
                }


                {
                    overviewData != null && clientData != null && (
                        <Modal
                            open={withdrawalModal}
                            onClose={() => {
                                setWithdrawalModal(false);
                            }}
                        >
                            <div className="modal_content">
                                <Typography variant="subtitle1">Withdrawal</Typography>
                                <br />
                                <TextField className="full_width" label="Amount"
                                    error={withdrawalError}
                                    helperText={withdrawalErrorText}
                                    onChange={handleChangeWithdrawal}
                                    value={withdrawalAmount}
                                    placeholder={"Max " + overviewData.balance}
                                ></TextField>
                                <br /><br />
                                {clientData.withdrawal_payment_method_type_id == 1 ? (
                                    <>
                                        <p><strong>Bank</strong>: {clientData.withdrawal_bank}</p>
                                        <p><strong>Account Name</strong>: {clientData.withdrawal_bank_account_name}</p>
                                        <p><strong>Account Number</strong>: {clientData.withdrawal_bank_account_number}</p></>
                                ) : (
                                    <>
                                        <p><strong>USDT Address</strong>: {clientData.withdrawal_USDT_address}

                                        </p>
                                    </>
                                )}

                                <br /><br />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end"
                                }}>
                                    <Button
                                        onClick={() => {
                                            handleSubmitWithdrawal();
                                        }}
                                        variant="contained">Submit</Button>
                                </div>
                            </div>
                        </Modal>
                    )
                }

                {
                    clientData != null && (
                        <Modal
                            open={depositModal}
                            onClose={() => {
                                setDepositModal(false);
                            }}
                        >
                            <div className="modal_content">
                                <Typography variant="subtitle1">Deposit</Typography>
                                <br />
                                <TextField className="full_width" label="Amount"
                                    name="amount"
                                    onChange={handleChangeDeposit}
                                    value={depositForm.amount}
                                ></TextField>
                                <br /><br />
                                <TextField className="full_width" label="Remarks" name="remarks"
                                    value={depositForm.remarks} onChange={handleChangeDeposit}
                                ></TextField>
                                <br /><br />
                                <TextField label="upload_receipt" type="file" onChange={handleChangeImageAttachment} InputLabelProps={{
                                    shrink: true
                                }}
                                    className="full_width"
                                />
                                <br /><br />
                                <p><strong>Bank</strong>: {clientData.deposit_bank}</p>
                                <p><strong>Account Name</strong>: {clientData.deposit_bank_account_name}</p>
                                <p><strong>Account Number</strong>: {clientData.deposit_bank_account_number}</p>
                                <br /><br />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end"
                                }}>
                                    <Button
                                        onClick={() => {
                                            handleSubmitDeposit();
                                        }}
                                        variant="contained">Submit</Button>
                                </div>
                            </div>
                        </Modal>
                    )
                }


            </Container >
        </Page >
    );
}
