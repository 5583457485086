import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, TextField, MenuItem, FormGroup } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock
import { countries } from '../../../_mock';
// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel'
import { onNextStep } from 'src/redux/slices/product';
import { get_countries, get_currency_from_country, create_client, check_username_exist } from 'src/api/API';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

ClientNewForm.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object,
};


export default function ClientNewForm({ isEdit, currentUser }) {
    const navigate = useNavigate();

    const { allLang, currentLang, translate, onChangeLang } = useLocales();
    const [countries, setCountries] = React.useState([]);
    const [currencies, setCurrencies] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = React.useState(0);

    const [skipped, setSkipped] = React.useState(new Set());
    const steps = [translate('General'), translate('Create User')];
    useEffect(() => {
        get_country_list();
    }, []);

    const get_country_list = async () => {
        var response = await get_countries();
        if (response.status) {
            setCountries(response.data);
        }
    };

    const get_currency = async (country_id) => {
        var response = await get_currency_from_country({ country_id: country_id });
        if (response.status) {
            setCurrencies(response.data);
        }
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = async () => {
        let newSkipped = skipped;
        if (activeStep == 0) {
            if (websiteForm.name == '') {
                alert("name cannot be empty");
                return;
            }
            if (websiteForm.website == '') {
                alert("website cannot be empty");
                return;
            }
            if (websiteForm.country == 0) {
                alert("country cannot be empty");
                return;
            }

            if (websiteForm.currency == 0) {
                alert("currency cannot be empty");
                return;
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep == 1) {
            if (websiteForm.username == '') {
                alert("please enter a username"); return;
            } else if (websiteForm.email == '') {
                alert("please enter an email"); return;
            } else if (websiteForm.password == '') {
                alert("please enter a password"); return;
            } else if (websiteForm.password != websiteForm.password2) {
                alert("passwords do not match"); return;
            }

            var checkExist = await check_username_exist({ username: websiteForm.username });
            if (checkExist.status) {
                if (checkExist.result) {
                    setUsernameError("Please select another username");
                } else {
                    setUsernameError("");
                    handleSubmit();
                }
            }


        }



    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        website: Yup.string().required("Website is required"),
        country: Yup.string().required('Country is required'),

        // avatarUrl: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
    });

    const [usernameError, setUsernameError] = React.useState("");

    const [websiteForm, setWebsiteForm] = React.useState({
        name: "",
        website: "",
        country: 0,
        currency: 0,
        username: "",
        email: "",
        password: "",
        password2: "",
        rate: 2.5,
        bank: "",
        bank_account_number: "",
        bank_account_name: ""
    });

    const handleChange = (e) => {
        setWebsiteForm({
            ...websiteForm,
            [e.target.name]: e.target.value
        });
    };

    const defaultValues = useMemo(
        () => ({
            name: currentUser?.name || '',
            country: currentUser?.country || '',
            currency: "",
            website: "",

            username: "",
            email: "",
            password: "",
            password2: "",

            rate: 2.5,
            bank: "",
            bank_account_number: "",
            bank_account_name: ""

        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentUser]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });



    const handleSubmit = async () => {
        try {

            var form = {
                ...websiteForm
            }
            var response = await create_client(form);
            if (response.status) {

                enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
                navigate(PATH_DASHBOARD.clients.root);
            } else {
                alert(response.message);
            }



        } catch (error) {
            console.error(error);
        }
    };


    return (

        <Grid container spacing={3}>


            <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep == 0 && (
                        <Box
                            style={{
                                marginTop: 20
                            }}
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            <TextField name="name" value={websiteForm.name} label={translate("Name")} onChange={handleChange} />
                            <TextField name="website" label={translate("Website")} value={websiteForm.website} onChange={handleChange} />
                            <TextField name="country" value={websiteForm.country} label={translate("Country")} select

                                onChange={(el) => {
                                    handleChange(el);
                                    get_currency(el.target.value);
                                }}
                            >
                                {countries.map(row => (
                                    <MenuItem value={row.country_id}>{row.country}</MenuItem>
                                ))}
                            </TextField>
                            <TextField name="currency" label={translate("Currency")} select onChange={handleChange} value={websiteForm.currency}>
                                {currencies.map(row => (
                                    <MenuItem value={row.currency_id}>{row.currency}</MenuItem>
                                ))}
                            </TextField>

                        </Box>
                    )}

                    {activeStep == 1 && (
                        <Box
                            style={{
                                marginTop: 20
                            }}
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            <TextField onChange={handleChange}
                                helperText={usernameError} error={usernameError != ''}
                                value={websiteForm.username} name="username" label={translate("username")} />
                            <TextField onChange={handleChange} name="email" label={translate("email")} value={websiteForm.email} />
                            <TextField onChange={handleChange} name="password" type="password" label={translate("password")} value={websiteForm.password} />
                            <TextField onChange={handleChange} name="password2" type="password" label={translate("confirm password")} value={websiteForm.password2} />
                            {/* <p>* A temporary password will be sent to the user's email</p> */}
                        </Box>
                    )}


                    {activeStep == 2 && (
                        <Box
                            style={{
                                marginTop: 20
                            }}
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            {/* <FormGroup>
                                    <FormControlLabel control={<Switch />} label="paypal" />
                                    <TextField name="payment_method_1" label="rate" type="number" min="4.2" step="0.01" />
                                </FormGroup> */}
                            <div className='well'>
                                <FormGroup>
                                    <FormControlLabel control={<Switch />} label="manual" />
                                    <TextField
                                        inputProps={{
                                            step: 0.01
                                        }}
                                        name="rate" label="rate" type="number" min="4.2" step="0.01" />

                                </FormGroup>
                                <br />
                                <RHFTextField name="bank" label="Bank" />
                                <br /><br />
                                <RHFTextField name="bank_account_number" label="Bank Account Number" />
                                <br /><br />
                                <RHFTextField name="bank_account_name" label="Bank Account Name" />
                            </div>
                        </Box>
                    )}


                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        {activeStep == 0 ?
                            <LoadingButton type="button" variant="contained" onClick={() => {
                                handleNext();
                            }}>
                                {translate("Next")}
                            </LoadingButton> :
                            <LoadingButton variant="contained" onClick={handleNext}>
                                {!isEdit ? 'Create Client' : 'Save Changes'}
                            </LoadingButton>
                        }

                    </Stack>
                </Card>
            </Grid>
        </Grid>

    );
}
