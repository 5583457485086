// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    roles: [1, 2, 3],
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      // {
      //   title: "Withdrawals",
      //   path: PATH_DASHBOARD.withdrawals.all,
      //   icon: ICONS.user,
      //   children: [
      //     { title: "Clients", path: PATH_DASHBOARD.withdrawals.clients },
      //     { title: "Customers", path: PATH_DASHBOARD.withdrawals.customer }
      //   ]
      // },
    ],
  },
  {
    subheader: "CUSTOMERS",
    roles: [1, 2, 3],
    items: [
      { title: "Payments", path: PATH_DASHBOARD.payments.all, icon: ICONS.dashboard },

      { title: "Withdrawals", path: PATH_DASHBOARD.withdrawals.customer, icon: ICONS.dashboard },
    ]
  },
  {
    subheader: "CLIENTS",
    roles: [1, 2, 3],
    items: [
      { title: "Deposits", path: PATH_DASHBOARD.deposits.all, icon: ICONS.dashboard },

      { title: "Cash Out", path: PATH_DASHBOARD.withdrawals.clients, icon: ICONS.dashboard },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'MANAGEMENT',
    roles: [1, 2],
    items: [
      // MANAGEMENT : USER 
      {
        title: "Clients",
        path: PATH_DASHBOARD.clients.root,
        icon: ICONS.user,
        children: [
          { title: "All", path: PATH_DASHBOARD.clients.root },
          { title: "Create", path: PATH_DASHBOARD.clients.create }
        ]
      },
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'All', path: PATH_DASHBOARD.user.list },
          { title: 'Create', path: PATH_DASHBOARD.user.newUser },
          // { title: 'edit', path: PATH_DASHBOARD.user.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },


    ],
  },

  {
    subheader: 'SETTINGS',
    roles: [1],
    items: [
      // MANAGEMENT : USER 
      {
        title: "Countries",
        path: PATH_DASHBOARD.settings.countries,
        icon: ICONS.user,
        children: [
          { title: "All", path: PATH_DASHBOARD.settings.countries },
          { title: "Create", path: PATH_DASHBOARD.settings.create_country }
        ]
      },

    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export const navConfigUser = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      // {
      //   title: "Withdrawals",
      //   path: PATH_DASHBOARD.withdrawals.all,
      //   icon: ICONS.user,
      //   children: [
      //     { title: "Clients", path: PATH_DASHBOARD.withdrawals.clients },
      //     { title: "Customers", path: PATH_DASHBOARD.withdrawals.customer }
      //   ]
      // },
    ],
  },
  {
    subheader: "CUSTOMERS",
    items: [
      { title: "Payments", path: PATH_DASHBOARD.payments.all, icon: ICONS.dashboard },

      { title: "Withdrawals", path: PATH_DASHBOARD.withdrawals.customer, icon: ICONS.dashboard },
    ]
  },
  {
    subheader: "CLIENTS",
    items: [
      { title: "Deposits", path: PATH_DASHBOARD.deposits.all, icon: ICONS.dashboard },

      { title: "Cash Out", path: PATH_DASHBOARD.withdrawals.clients, icon: ICONS.dashboard },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'MANAGEMENT',
    items: [
      // MANAGEMENT : USER 
      {
        title: "Clients",
        path: PATH_DASHBOARD.clients.root,
        icon: ICONS.user,
        children: [
          { title: "All", path: PATH_DASHBOARD.clients.root },
        ]
      },
    ],
  },



];


export default navConfig;
