

import axios from "axios";
const host = process.env.REACT_APP_HOST_API_KEY;
// const host = "http://paymentv2.cysoft.co/payment_backend/";

// const access_endpoint_link = host + "Access_Api/";
// const endpoint_link = host + "api/";

const format_request = (postparam) => {
    postparam['user_token'] = window.localStorage.getItem("accessToken");
    postparam['user_id'] = window.localStorage.getItem("user_id");
    postparam['role'] = window.localStorage.getItem("role");
    let body = '';
    for (let k in postparam) {
        body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
    }
    var postOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body
    };
    return postOptions;
}

export const upload_deposit_receipt = async (postparam) => {
    var response = await axios.post(host + 'API/upload_deposit_receipt', postparam);

    if (response.data.status) {
        return response.data;
    }
}

export const client_approve_withdrawal = async (postparam) => {
    var response = await fetch(host + "API/client_approve_withdrawal", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        console.log(response);
        return response;
    }
}

export const client_reject_withdrawal = async (postparam) => {
    var response = await fetch(host + "API/client_reject_withdrawal", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        console.log(response);
        return response;
    }
}
export const create_client = async (postparam) => {
    var response = await fetch(host + "API/create_client", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        console.log(response);
        return response;
    }
}

export const unset_deposit_to_process = async (postparam) => {
    var response = await fetch(host + "API/unset_deposit_to_admin", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const set_deposit_to_process = async (postparam) => {
    var response = await fetch(host + "API/set_deposit_to_admin", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const set_withdrawal_customer_to_process = async (postparam) => {
    var response = await fetch(host + "API/set_withdrawal_customer_to_process", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const unset_withdrawal_customer_to_process = async (postparam) => {
    var response = await fetch(host + "API/unset_withdrawal_customer_to_process", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const set_withdrawal_client_to_process = async (postparam) => {
    var response = await fetch(host + "API/set_withdrawal_client_to_process", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const unset_withdrawal_client_to_process = async (postparam) => {
    var response = await fetch(host + "API/unset_withdrawal_client_to_process", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const set_payment_to_process = async (postparam) => {
    var response = await fetch(host + "API/set_payment_to_admin", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const unset_payment_to_process = async (postparam) => {
    var response = await fetch(host + "API/unset_payment_to_admin", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_website_user = async (postparam) => {
    var response = await fetch(host + "API/get_website_user", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const create_admin = async (postparam) => {
    var response = await fetch(host + "API/create_admin", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        console.log("API", response);
        return response;
    } else {
        return response;
    }
}

export const update_integration_urls = async (postparam) => {
    var response = await fetch(host + "API/update_integration_url", format_request(postparam));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const create_integration_key = async (postparam) => {
    var response = await fetch(host + "API/create_integration_key", format_request(postparam));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const client_deposit = async (postparam) => {
    var response = await fetch(host + "API/client_deposit", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const client_withdrawal = async (postparam) => {
    var response = await fetch(host + "API/client_withdrawal", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const update_website_transfer_info = async (postparam) => {
    var response = await fetch(host + "API/update_website_transfer_info", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_website_withdrawal_rates = async (postparam) => {
    var response = await fetch(host + "API/update_website_withdrawal_rates", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_website_payment_methods = async (postparam) => {
    var response = await fetch(host + "API/get_website_payment_methods", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}


export const update_website_payment_methods = async (postparam) => {
    var response = await fetch(host + "API/update_website_payment_methods", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}


export const get_admin = async () => {
    var response = await fetch(host + "API/get_admin");
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const get_rejection_reason = async () => {
    var response = await fetch(host + "API/get_rejection_reason");
    var response = await response.json();

    if (response.status) {
        return response;
    }
}



export const update_deposit_status = async (postparam) => {
    var response = await fetch(host + "API/update_deposit_status", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_payment_status = async (postparam) => {
    var response = await fetch(host + "API/update_payment_status", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_my_users = async () => {
    var response = await fetch(host + "API/get_my_users", format_request({}));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const delete_website_payment_method = async (postparam) => {
    var response = await fetch(host + "API/delete_website_payment_method", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const add_payment_method_website = async (postparam) => {
    var response = await fetch(host + "API/add_payment_method_website", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_available_methods_for_website = async (postparam) => {
    var response = await fetch(host + "API/get_available_methods_for_website", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_credentials = async (postparam) => {
    var response = await fetch(host + "API/get_credentials", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const check_username_exist = async (postparam) => {
    var response = await fetch(host + "API/check_username_exist", format_request(postparam));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}
export const get_clients = async (postparam) => {
    var response = await fetch(host + "API/get_clients", format_request({}));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_client_overview = async (postparam) => {
    var response = await fetch(host + "API/get_client_overview", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_client_details = async (postparam) => {
    var response = await fetch(host + "API/get_client_details", format_request(postparam));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const get_payments = async (postparam) => {
    var response = await fetch(host + "API/get_payments", format_request(postparam));
    var response = await response.json();

    if (response.data) {
        console.log(response);
        return response;
    }
}

export const download_payments = async (postparam) => {
    var response = await fetch(host + "API/download_payments", format_request(postparam));
    var response = await response.json();

    if (response.data) {
        console.log(response);
        return response;
    }
}



export const get_deposits = async (postparam) => {
    var response = await fetch(host + "API/get_deposits", format_request(postparam));
    var response = await response.json();

    if (response.data) {
        console.log(response);
        return response;
    }
}

export const get_withdrawal_user = async (postparam) => {
    var response = await fetch(host + "API/get_user_withdrawals", format_request(postparam));
    var response = await response.json();

    if (response.data) {
        return response;
    }
}

export const download_withdrawal_user = async (postparam) => {
    var response = await fetch(host + "API/download_user_withdrawals", format_request(postparam));
    var response = await response.json();

    if (response.data) {
        return response;
    }
}


export const get_withdrawal_client = async (postparam) => {
    var response = await fetch(host + "API/get_client_withdrawals", format_request(postparam));
    var response = await response.json();

    if (response.data) {
        return response;
    }
}

export const get_website_transaction_history = async (postparam) => {
    var response = await fetch(host + "API/get_website_transaction_history", format_request(postparam));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const get_payment_detail = async (postparam) => {
    var response = await fetch(host + "API/get_payment_detail", format_request(postparam));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const get_countries = async () => {
    var response = await axios.get(host + "API/get_countries");
    if (response.data) {
        return response.data;
    }
}

export const add_country = async (data) => {
    var response = await fetch(host + "API/add_country", format_request(data));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const update_withdrawal_client_status = async (data) => {
    var response = await fetch(host + "API/update_withdrawal_client_status", format_request(data));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}


export const update_withdrawal_status = async (data) => {
    var response = await fetch(host + "API/update_withdrawal_status", format_request(data));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const get_currency_from_country = async (data) => {
    var response = await fetch(host + "API/get_currency_from_country", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_admin_detail = async (data) => {
    var response = await fetch(host + "API/get_admin_detail", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_not_admin_client = async (data) => {
    var response = await fetch(host + "API/get_not_admin_client", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_user_info = async (data) => {
    console.log("temp", data);
    var response = await fetch(host + "API/get_user_info", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const edit_user_info = async (data) => {
    var response = await fetch(host + "API/edit_user_info", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const edit_admin_info = async (data) => {
    var response = await fetch(host + "API/edit_admin_info", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const add_admin_client = async (data) => {
    var response = await fetch(host + "API/add_admin_client", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_admin_roles = async () => {
    var response = await fetch(host + "API/get_admin_roles", format_request({}));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const delete_admin_client = async (data) => {
    var response = await fetch(host + "API/delete_admin_client", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_admin_client = async (data) => {
    var response = await fetch(host + "API/update_admin_client", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_payment_method_type = async () => {
    var response = await fetch(host + "API/get_payment_method_type", format_request({}));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_payment_methods_from_country = async (data) => {
    var response = await fetch(host + "API/get_payment_methods_from_country", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}


export const get_country_details = async (data) => {
    var response = await fetch(host + "API/get_country_info", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_country_payment_method = async (data) => {
    var response = await fetch(host + "API/get_country_payment_method", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const add_country_payment_method = async (data) => {
    var response = await fetch(host + "API/add_country_payment_method", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const update_country_details = async (data) => {
    var response = await fetch(host + "API/update_country_details", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
};

export const add_currency_to_country = async (data) => {
    var response = await fetch(host + "API/add_currency_to_country", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const set_country_payment_active = async (data) => {
    var response = await fetch(host + "API/set_country_payment_active", format_request(data));
    var response = await response.json();
    if (response.status) {
        return response;
    }
}

export const get_country_fields = async (data) => {
    var response = await fetch(host + "API/get_country_field", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const add_country_fields = async (data) => {
    var response = await fetch(host + "API/add_country_fields", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_country_field = async (data) => {
    var response = await fetch(host + "API/update_country_field", format_request(data));
    var respones = await response.json();
    if (response.status) {
        return response;
    }
}

export const get_country_payment_method_details = async (data) => {
    var response = await fetch(host + "API/get_country_payment_method_detail", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const add_country_payment_method_field = async (data) => {
    var response = await fetch(host + "API/add_country_payment_method_field", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_country_payment_method_field_required = async (data) => {
    var response = await fetch(host + "API/update_country_payment_method_field_required", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const remove_payment_form_field = async (data) => {
    var response = await fetch(host + "API/remove_payment_form_field", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const remove_country_payment_method_field = async (data) => {
    var response = await fetch(host + "API/remove_country_payment_method_field", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_languages = async () => {
    var response = await fetch(host + "API/get_languages", format_request({}));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const delete_country_payment_method = async (data) => {
    var response = await fetch(host + "API/delete_country_payment_method", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const get_language_message = async (data) => {
    var response = await fetch(host + "API/get_language_message", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const amend_payment = async (data) => {
    var response = await fetch(host + "API/amend_payment", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const amend_withdraw = async (data) => {
    var response = await fetch(host + "API/amend_withdraw", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_languages_with_message = async (data) => {
    var response = await fetch(host + "API/get_languages_with_message", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}
export const add_new_language_message = async (data) => {
    var response = await fetch(host + "API/add_new_language_message", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_language_message = async (data) => {
    var response = await fetch(host + "API/update_language_message", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const delete_language_message = async (data) => {
    var response = await fetch(host + "API/delete_language_message", format_request(data));
    var response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_dashboard = async (data) => {
    var response = await fetch(host + "API/get_dashboard", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}

export const save_website_limits = async (data) => {
    var response = await fetch(host + "API/save_website_limits", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}

export const change_user_password = async (data) => {
    var response = await fetch(host + "API/change_user_password", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
};

export const replay_payment_callback = async (data) => {
    var response = await fetch(host + "API/replay_payment_callback", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}

export const replay_withdrawal_user_callback = async (data) => {
    var response = await fetch(host + "API/replay_withdrawal_user_callback", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}

export const get_website_ip = async (data) => {
    var response = await fetch(host + "API/get_website_ip", format_request(data));
    response = await response.json();
    if (response.status) {
        return response;
    }
}

export const add_website_ip = async (data) => {
    var response = await fetch(host + "API/add_website_ip", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}

export const remove_website_ip = async (data) => {
    var response = await fetch(host + "API/remove_website_ip", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}

export const update_website_general = async (data) => {
    var response = await fetch(host + "API/update_website_general", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}



export const get_withdrawal_detail = async (data) => {
    var response = await fetch(host + "API/get_withdrawal_detail", format_request(data));
    response = await response.json();

    if (response.status) {
        return response;
    }
}
