import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, TextField, Stack, Switch, Typography, FormControlLabel, MenuItem } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock
import { countries } from '../../../_mock';
// components
import Label from '../../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import { create_admin, get_admin_roles } from 'src/api/API';

// ----------------------------------------------------------------------

UserNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function UserNewForm({ isEdit, currentUser }) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    username: Yup.string().required('Name is required'),
    password: Yup.string().required("Password is required"),
    password2: Yup.string().required("Please confirm your password"),
  });

  const [roleForm, setRoleForm] = useState(1);
  const defaultValues = useMemo(
    () => ({
      username: "",
      password: "",
      password2: ""
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );


  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [roles, setRoles] = useState([]);
  const init_roles = async () => {
    var response = await get_admin_roles();
    if (response.status) {
      setRoles(response.data);
    }
  };
  useEffect(() => {
    init_roles();
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async (form) => {
    form['role_id'] = roleForm;
    var response = await create_admin(form);
    if (response) {
      console.log(response);
      if (response.status) {
        reset();
        enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
        navigate(PATH_DASHBOARD.user.list);
      } else {
        console.log(response)
        alert(response.message);
      }
    }else{
      console.log("OUT", response);
    }


  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>


        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="username" label="Username" />
              <RHFTextField name="password" type="password" label="Password" />
              <RHFTextField name="password2" label="Confirm Password" type="password" />
              <TextField name="role_id" label="Role" onChange={(e) => {
                setRoleForm(e.target.value);
              }} select>
                {roles.map(row => (
                  <MenuItem value={row.role_id}>{row.role}</MenuItem>
                ))}
              </TextField>

            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create User' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
