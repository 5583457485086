import PropTypes, { array } from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useLocales from 'src/hooks/useLocales';
import useAuth from 'src/hooks/useAuth';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);



// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {

  const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const { user } = useAuth();
  useEffect(() => {
    console.log("USER", user);
  }, []);
  return (
    <Box {...other}>
      {navConfig.map((group) => {
        // console.log("GROUP ", group.roles, parseInt(user.role_id), group.roles.indexOf(parseInt(user.role_id)));
        if (process.env.REACT_APP_USER_TYPE == 'admin') {
          if (group.roles.indexOf(parseInt(user.role_id)) == -1) {

            return;
          }
        }

        return (
          <List key={(group.subheader)} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate(group.subheader)}
            </ListSubheaderStyle>

            {group.items.map((list) => (
              <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
            ))}
          </List>)
      })}
    </Box>
  );
}
