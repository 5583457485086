import { paramCase, capitalCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import UserNewForm from '../../sections/@dashboard/user/UserNewForm';
import ClientNewForm from 'src/sections/@dashboard/client/ClientNewForm';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

export default function ClientCreate() {
    const { themeStretch } = useSettings();
    const { pathname } = useLocation();
    const { name = '' } = useParams();
    const isEdit = pathname.includes('edit');
    const { allLang, currentLang, translate, onChangeLang } = useLocales();

    const currentUser = _userList.find((user) => paramCase(user.name) === name);

    return (
        <Page title="User: Create a new Client">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={!isEdit ? translate('Create a new Client') : 'Edit Client'}
                    links={[
                        { name: translate("Clients"), href: PATH_DASHBOARD.clients },
                        { name: !isEdit ? translate("New Client") : capitalCase(name) },
                    ]}
                />
                <ClientNewForm isEdit={isEdit} currentUser={currentUser} />
            </Container>
        </Page>
    );
}
