import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card, Box, Typography, CardHeader, Button, List, ListItemButton, Divider, ListItem, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Modal, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { addIcon } from '@iconify/react';
import SvgIconStyle from 'src/components/SvgIconStyle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { get_credentials, update_integration_urls, create_integration_key, get_website_ip, add_website_ip, remove_website_ip } from 'src/api/API';
import useLocales from 'src/hooks/useLocales';
import { async } from '@firebase/util';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export default function AccountIntegrations(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const ChangePassWordSchema = Yup.object().shape({
    callback_url: Yup.string().required('callback url required'),
    withdrawal_callback_url: Yup.string().required('withdrawal callback url required'),
    port_number: Yup.string().required("Port number required"),
    return_url: Yup.string().required("return url required"),
  });

  const [credentials, setCredentials] = React.useState([]);
  const [ips, setIps] = React.useState([]);
  const [openCredModal, setOpenCredModal] = React.useState(false);
  const [newCred, setNewCred] = React.useState({
    api_key: "",
    api_secret: ""
  });
  useEffect(() => {
    get_data();
  }, []);
  const get_data = () => {
    get_cred();
  };

  const [ipForm, setIpForm] = React.useState({
    ip: ""
  });
  const get_cred = async () => {
    var response = await get_credentials({ website_id: props.websiteId });
    if (response.status) {
      setCredentials(response.data);
    }

    var res2 = await get_website_ip({ website_id: props.websiteId });
    if (res2.status) {
      setIps(res2.data);
    }
  };

  const handleAddIp = async () => {
    var response = await add_website_ip({
      website_id: props.websiteId,
      ip: ipForm.ip
    });
    if (response.status) {
      get_cred();
    }
  };

  const handleRemoveIp = async (id) => {
    var response = await remove_website_ip({ id: id });
    if (response.status) {
      get_cred();
    }
  };
  const defaultValues = {
    callback_url: props.data.callback_url,
    port_number: props.data.port_number,
    withdrawal_callback_url: props.data.withdrawal_callback_url,
    return_url: props.data.return_url,

  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (form) => {
    try {
      var response = await update_integration_urls({
        ...form,
        website_id: props.websiteId
      });
      if (response.status) {
        enqueueSnackbar('Update success!');
      }

    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateKey = async () => {
    var response = await create_integration_key({
      website_id: props.websiteId
    });
    if (response.status) {
      setNewCred(response.data);
      setOpenCredModal(true);
      get_cred();
    }
  };

  return (
    <>
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} alignItems="flex-end">

            <RHFTextField name="callback_url" label="Callback URL" />
            <RHFTextField name="withdrawal_callback_url" label="Withdrawal Callback URL" />
            <RHFTextField name="port_number" label="Port Number" />
            <RHFTextField name="return_url" label="Return URL" />

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {translate("Save Changes")}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
      <Card sx={{ p: 3 }} style={{
        marginTop: 20
      }}>
        <CardHeader
          title={
            <Typography variant="h6">
              {translate("Credentials")}
            </Typography>
          }

          action={<Button variant="contained"
            onClick={handleGenerateKey}
          >{translate("Generate")}</Button>}

          sx={{ mb: 3 }}
        />
        <Table>
          <TableHead>
            <TableRow>

              <TableCell>{translate("API KEY")}</TableCell>
              <TableCell>{translate("Last Used")}</TableCell>
              <TableCell>{translate("Created")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {credentials.map(row => (
              <TableRow>
                {/* <TableCell><bold>{row.name}</bold></TableCell> */}
                <TableCell>{row.api_key}</TableCell>
                <TableCell>{row.last_used}</TableCell>
                <TableCell>{row.created_date}</TableCell>
                <TableCell>
                  <IconButton>
                    {/* <DeleteIcon></DeleteIcon> */}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </Card>
      <Card sx={{ p: 3 }} style={{ marginTop: 20 }}>
        <CardHeader
          title={
            <Typography variant="h6">
              {translate("IP")}
            </Typography>
          }



          sx={{ mb: 3 }}
        />
        <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", marginBottom: 16 }}>
          <TextField value={ipForm.ip} onChange={(e) => { setIpForm({ ip: e.target.value }) }} label={"IP"} style={{
            marginRight: 20
          }} />
          <Button variant="contained" onClick={handleAddIp}>Add</Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>

              <TableCell>{translate("IP")}</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ips.map(row => (
              <TableRow>
                {/* <TableCell><bold>{row.name}</bold></TableCell> */}
                <TableCell>{row.ip}</TableCell>

                <TableCell>
                  <IconButton>
                    <DeleteIcon onClick={() => {
                      handleRemoveIp(row.whitelist_request_ip_id)
                    }}></DeleteIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </Card>

      <Modal
        open={openCredModal}
        onClose={() => { setOpenCredModal(false) }}
      >
        <div className='modal_content'>
          <Typography variant="subtitle2">{translate("Your new credentials")}</Typography>
          <br />
          <TextField className='full_width' value={newCred.api_key} label={translate("API KEY")}></TextField>
          <br /><br />
          <TextField className='full_width' value={newCred.api_secret} label="Secret"></TextField>
          <p><small>{translate("Please store your secret in a safe place")}</small></p>
          <div style={{
            marginTop: 10,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}>
            <Button onClick={() => {
              setOpenCredModal(false);
            }} variant="contained">{translate("Close")}</Button>
          </div>
        </div>

      </Modal>
    </>
  );
}
