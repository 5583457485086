import merge from 'lodash/merge';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, TextField, } from '@mui/material';
// components
import { BaseOptionChart } from '../../../../components/chart';

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import useLocales from 'src/hooks/useLocales';


// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    year: 2019,
    data: [
      { name: 'Payments', data: [10, 41, 35, 51, 49, 62, 69, 91, 148] },
      { name: 'Withdrawals', data: [10, 34, 13, 56, 77, 88, 99, 77, 45] },
    ],
  },
  {
    year: 2020,
    data: [
      { name: 'Payments', data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
      { name: 'Withdrawals', data: [45, 77, 99, 88, 77, 56, 13, 34, 10] },
    ],
  },
];

export default function AppAreaInstalled(props) {

  const [seriesData, setSeriesData] = useState(2019);
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [tableData, setTableData] = useState([]);

  const { allLang, currentLang, translate, onChangeLang } = useLocales();
  useEffect(() => {
    var _data = [];
    _data.push({
      name: translate("Payments"),
      data: props.payments
    });
    _data.push({
      name: translate("Withdraws"),
      data: props.withdraws
    });
    setData(_data);

    setDates(props.dates);
    console.log(_data);

    var _table_data = [];
    var _opening = {
      "label": translate("Opening Balance")
    };
    var _end_balance = {
      label: translate("End Balance")
    };
    var _client_deposit = {
      label: translate("Client Deposit")
    };
    var _client_withdraw = {
      label: translate("Client Withdraw")
    };
    var _closing_balance = {
      label: translate("Closing Balance")
    };
    var _payment_fees = {
      label: translate("Payment Fees")
    };
    var _total_payments = {
      label: translate("Total Payments")
    };
    var _total_payments_count = {
      label: translate("Total No Of Payments")
    };
    var _total_withdraws = {
      label: translate("Total Payouts")
    };
    var _total_withdraws_count = {
      label: translate("No of Payouts")
    }
    var _bank_fees = {
      label: translate("Bank Fees")
    };
    var _withdraw_fees = {
      label: translate("Payout Fees")
    };

    if (props.data) {
      for (var i = 0; i < props.data.length; i++) {
        var _row = props.data[i];
        _client_withdraw[_row.date] = _row.client_withdraw;
        _client_deposit[_row.date] = _row.client_deposit;
        _bank_fees[_row.date] = _row.bankFees;
        _opening[_row.date] = _row.opening;
        _end_balance[_row.date] = _row.end_balance;
        _closing_balance[_row.date] = _row.closing_balance;
        _payment_fees[_row.date] = _row.paymentFees;
        _total_payments[_row.date] = _row.total_payments;
        _total_payments_count[_row.date] = _row.total_payments_count;
        _total_withdraws[_row.date] = _row.total_withdraws;
        _total_withdraws_count[_row.date] = _row.total_withdraws_count;
        _closing_balance[_row.date] = _row.end_balance + _row.client_withdraw;
        _withdraw_fees[_row.date] = _row.withdrawFees;
      }

        _table_data.push(_opening);
      _table_data.push(_client_deposit);
      _table_data.push(_total_payments);
      _table_data.push(_total_payments_count);
      _table_data.push(_payment_fees);
      _table_data.push(_total_withdraws);
      _table_data.push(_total_withdraws_count);
      _table_data.push(_withdraw_fees);
      _table_data.push(_bank_fees);

        _table_data.push(_closing_balance);

      _table_data.push(_client_withdraw);
      
        _table_data.push(_end_balance);
      setTableData(_table_data);

    }

  }, [props]);
  const handleChangeSeriesData = (event) => {
    setSeriesData(Number(event.target.value));
  };


  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: [...props.dates],
    },
  });

  return (
    <Card>
      <CardHeader
        title="Transactions"
      // subheader="(+43%) than last year"
      // action={
      //   <TextField
      //     select
      //     fullWidth
      //     value={seriesData}
      //     SelectProps={{ native: true }}
      //     onChange={handleChangeSeriesData}
      //     sx={{
      //       '& fieldset': { border: '0 !important' },
      //       '& select': {
      //         pl: 1,
      //         py: 0.5,
      //         pr: '24px !important',
      //         typography: 'subtitle2',
      //       },
      //       '& .MuiOutlinedInput-root': {
      //         borderRadius: 0.75,
      //         bgcolor: 'background.neutral',
      //       },
      //       '& .MuiNativeSelect-icon': {
      //         top: 4,
      //         right: 0,
      //         width: 20,
      //         height: 20,
      //       },
      //     }}
      //   >
      //     {CHART_DATA.map((option) => (
      //       <option key={option.year} value={option.year}>
      //         {option.year}
      //       </option>
      //     ))}
      //   </TextField>
      // }
      />


      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        {data.length > 0 && (
          <ReactApexChart type="line" series={data} options={chartOptions} height={364} />
        )}
        <Table
          data={tableData}
          height={800}
        >

          <Column width={200} fixed>
            <HeaderCell></HeaderCell>
            <Cell dataKey='label' />
          </Column>
          {dates.map(r => (
            <Column width={150}>
              <HeaderCell>{r}</HeaderCell>
              <Cell dataKey={r} />
            </Column>
          ))}
        </Table>

        {/* <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {dates.map(d => (
                <TableCell>{d}</TableCell>
              ))}
            </TableRow>

          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Opening Balance</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Total Received</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>No. Of Payments</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Platform Fees</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Total Outgoing</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>No. Of Outgoing</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Closing Balance</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Balance</TableCell>
              {dates.map(d => (
                <TableCell></TableCell>
              ))}
            </TableRow>
    
          </TableBody>
        </Table> */}
      </Box>
    </Card>
  );
}
